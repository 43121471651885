<template>
  <div class="login-warp">
    <div class="login-center">
      <div class="login-center-warp container">
        <div class="logo-form-warp">
          <div class="form-warp">
            <div class="logo-loading">
              <div class="logo1"></div>
            </div>
            <div style="color: #c29963; font-size: 2.5rem">
              Session
              <type-writer
                :speed="200"
                :full-erase="true"
                :interval="300"
                :words="['Timeout!']"
              >
              </type-writer>
            </div>
            <form class="logo-form">
              <div class="input-group visitor-warp">
                <button type="button" class="btn-back" @click="redirectLobby()">
                  Go Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeWriter from "@/components/common/Typewriter";
export default {
  name: "SessionExpired",
  components: {
    TypeWriter,
  },
  methods: {
    redirectLobby() {
      var v = localStorage.getItem("isMobile")
      if(v == 'true') {
        location.href = "/m/lobby" 
      } else {
        location.href ="/lobby"
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.login-warp {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url("/assets/images/loading/loading-background.jpg") no-repeat 50%;
  background-size: cover;
  box-sizing: border-box;
}
.login-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -15px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.login-center-warp {
  display: flex;
  margin-top: -70px;
  min-height: 100%;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}
.form-warp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo-loading {
  width: 300px;
  height: 340px;
  position: relative;
  background: url("/assets/images/defensil-logo-transparent.png") 50% no-repeat;
  background-size: contain;
}
.logo1 {
  width: 300px;
  height: 340px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("/assets/images/defensil-logo-white.png") 50% no-repeat;
  background-size: contain;
  mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -webkit-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -moz-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -o-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -ms-mask: url("/assets/images/defensil-logo-white.png") no-repeat;
  -webkit-mask-size: auto;
  mask-size: auto;
  -webkit-animation: maskPosition-data-v-06403c1a 2.5s infinite;
  animation: maskPosition-data-v-06403c1a 2.5s infinite;
}

@-webkit-keyframes maskPosition-data-v-06403c1a {
  0% {
    -webkit-mask-position: -5em;
  }
  to {
    -webkit-mask-position: 20em 30em;
  }
}

@keyframes maskPosition-data-v-06403c1a {
  0% {
    -webkit-mask-position: -5em;
  }
  to {
    -webkit-mask-position: 20em 30em;
  }
}

.logo-form {
  text-align: center;
}

.input-group {
  width: 400px;
  position: relative;
}

.visitor-warp {
  text-align: center;
  margin-bottom: 60px;
}

.btn-back {
  width: 100%;
  height: 50px;
  font-size: 20px;
  box-sizing: border-box;
  outline: none;
  color: #fff;
  background-color: #c29963;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  letter-spacing: 2px;
}
.btn-back:hover {
  background-color: #eda649;
  border: 1px solid white;
}


@media screen and (max-width: 375px) and (orientation: portrait) {
  .logo-form-warp {
    margin-top: 20%;
  }

  .logo-loading {
    width: 45vw;
    height: 30vh;
  }
  .logo1 {
    width: 45vw;
    height: 30vh;
  }
  .game-defensil {
    font-size: 5.5vw;
  }
}


@media screen and (max-width: 812px) and (orientation: landscape) {
  .logo-form-warp {
    margin-top: 20%;
  }

  .logo-loading {
    width: 45vw;
    height: 30vh;
  }
  .logo1 {
    width: 45vw;
    height: 30vh;
  }
  .game-defensil {
    font-size: 3.5vw;
  }
}
</style>
